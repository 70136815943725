import React, { useState, useEffect, useMemo } from 'react';
import { graphql, Link } from "gatsby"
import Seo from 'gatsby-plugin-wpgraphql-seo';
import Layout from "../components/Layout/Layout"
import $ from 'jquery';

const B2b_ecommerce = ({ data }) => {
  const { wpPage } = data;
//   console.log('data in case study', wpPage)

  useEffect(() => {
    $('.about__img').addClass('animate-img')
  }, []);

  return (
    <Layout>
      <Seo post={wpPage} />
      <section
        className='pageHeader'
        style={{
          background:
            wpPage.featuredImage != null
              ? `url(${wpPage.featuredImage.node.sourceUrl})`
              : '#d67373',
        }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12 col-md-12 col-lg-12'>
              <nav>
                <ol className='breadcrumb'>
                <li className='breadcrumb-item'><Link to="/">Home</Link></li>
                  <li className='breadcrumb-item active'>{wpPage.title}</li>
                </ol>
              </nav>
              <h1 className='pagetitle__heading'>{wpPage.title}</h1>

            </div>
          </div>
        </div>
      </section>
      <div className='postWrapper'>
        <div className='postContentWrapper'>
          <div className='postContent'>
            <div
              dangerouslySetInnerHTML={{ __html: wpPage.content }}
            />
          </div>
        </div>
      </div>


    </Layout>
  )
}

export const query = graphql`
  query {
     wpPage(slug: {eq: "b2b_ecommerce"}) {
      id
      title
      content
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      featuredImage {
        node {
          sourceUrl
        }
      }
    }
  }
`

export default B2b_ecommerce

